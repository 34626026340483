import React from 'react'
import './Landing.css'
import image from '../Assets/content.jpg'
import logo from '../Assets/logo.png'
import linkedin from '../Assets/linkedin.png'
import insta from '../Assets/insta.png'

export default function Landing() {
  return (
    <div id='landing-page'>
        <div id='landing-left'>
            <img id='landing-logo' src={logo}/>
            <p id='landing-txt'>Empowering Growth Through Strategic Collaboration</p>

            <h1 className='main-txt'>New Website <span className='main-txt' style={{color:'#d7d2cb'}}>Coming Soon</span></h1>
            <div id='social-container'>
                <a href='https://www.linkedin.com/company/76571866' target={'_blank'}>
                    <img className='landing-social' id='linkedin' src={linkedin}/>
                </a>
                <a href='https://www.instagram.com/stheagency' target={'_blank'}>
                    <img className='landing-social' id='insta' src={insta}/>
                </a>
            </div>
        </div>

        <img id='landing-image' src={image}/>
    </div>
  )
}
