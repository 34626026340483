import './App.css'
import React from 'react'
import {  ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Hero from './Components/Hero';
import Navbar from './Components/Navbar';
import AboutUs from './Components/AboutUs';
import WhatWeDo from './Components/WhatWeDo';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Overview from './Components/Overview';
import { register } from 'swiper/element/bundle';
import Academy from './Components/Academy';
import Landing from './Components/Landing';
// register Swiper custom elements
register();

function App() {
  
  return (
    <div >
      <ToastContainer position='top-center'/>
      <Landing />
      {/*<Navbar />
      <Hero />
      <Overview />
      <AboutUs />
      <WhatWeDo />
      <Academy />
      <Contact />
  <Footer />*/}
    </div>
  );
}

export default App;
